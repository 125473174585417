import ApiInstance from './ApiInstance.js'

export default {
  
  getNewsAlert() {
    return ApiInstance({ requiresAuth: true }).get('/news-alert');
  },
  addNewNewsAlert(Data) {
    return ApiInstance({ requiresAuth: true }).post('/news-alert', Data);
  },
  editNewsAlert(Data, id)
  {
    return ApiInstance({ requiresAuth: true }).put(`/news-alert/${id}`, Data);
  },
  deleteNewsAlert(id)
  {
    return ApiInstance({ requiresAuth: true }).delete(`/news-alert/${id}`);
  }
}
