<template>
  <div data-app>
    <div class="row mx-0 pt-3">
      <div class="col-md-7 pl-0 row mx-0">
        <div class="col-6 py-4 px-4 border_b_silver bg-white">
          <span class="card-label font-weight-bolder Main_Blue"
            >News Alert
          </span>
        </div>
        <div class="col-6 p-0 pt-3 text-end border_b_silver bg-white">
          <button
            type="button"
            class="custom-add-new-record-button btn_dash_ch mr-2"
            fab
            small
            @click="createFormEnable"
          >
            <span class="v-btn__content" style="font-size: 14px !important"
              >Add new</span
            >
          </button>
        </div>
        <v-data-table
          :headers="headers"
          :items="data"
          item-key="job-function"
          class="elevation-1 px-4 rounded-0 headers_job_function_ch"
        >
          <template v-slot:item="props">
            <tr class="border__ch">
              <td
                class="Main_Blue"
                style="font-size: 14px; font-weight: 500 !important"
              >
                {{ props.item.heading }}
              </td>
              <td
                class="Main_Blue"
                style="font-size: 14px; font-weight: 500 !important"
              >
                {{ props.item.users_type }}
              </td>
              <td class="border-0" style="">
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="editButtonClick(props.item)"
                >
                  <img
                    src="\images/job_application/Edit_Icon.svg"
                    alt=""
                    width="16"
                    height="16"
                  />
                </v-btn>
                <v-btn
                  class="py-2 ch_btn_silver_rounded_0"
                  fab
                  small
                  @click="askUserAboutDelete(props.item)"
                >
                  <img
                    src="\images/job_application/Delete.svg"
                    alt=""
                    width="16"
                    height="16"
                  />
                </v-btn>
              </td>
            </tr>
            <tr>
              <td colspan="12" style="height: 12px"></td>
            </tr>
          </template>
        </v-data-table>
      </div>
      <div class="col-md-5 pl-0 pt-0">
        <!-- create form start -->
        <div class="col-12 py-4 px-4 border_b_silver bg-white">
          <span
            v-if="createForm"
            class="card-label font-weight-bolder Main_Blue"
            >Add News Alert
          </span>
          <span
            v-if="updateForm"
            class="card-label font-weight-bolder Main_Blue"
            >Update News Alert
          </span>
        </div>
        <!-- create form start -->
        <div class="col-12 bg-white p-4">
          <v-form ref="form" v-if="createForm" v-model="valid" lazy-validation>
            <v-text-field
              v-model="heading"
              :rules="nameRules"
              label="Heading"
              required
            ></v-text-field>
            <v-text-field v-model="link" label="Link" required></v-text-field>
            <b-form-select
              v-model="users_type"
              :options="usersTypesOptions"
              size="sm"
              class="mt-3 mb-4 rounded-0"
            ></b-form-select>
            <button
              type="button"
              @click="createNewsAlert"
              class="custom-add-new-record-button btn_dash_ch mr-2"
            >
              <span
                class="v-btn__content px-4"
                style="font-size: 14px !important"
                >Save</span
              >
            </button>
          </v-form>
          <!-- create form end -->

          <!-- update form start -->
          <v-form ref="form" v-if="updateForm" v-model="valid" lazy-validation>
            <v-text-field
              v-model="heading"
              :rules="nameRules"
              label="Heading"
              required
            ></v-text-field>
            <v-text-field v-model="link" label="Link" required></v-text-field>
            <b-form-select
              v-model="users_type"
              :options="usersTypesOptions"
              size="sm"
              class="mt-3 mb-4 rounded-0"
            ></b-form-select>
            <button
              type="button"
              @click="updateNewsAlert"
              class="custom-add-new-record-button btn_dash_ch mr-2"
            >
              <span
                class="v-btn__content px-4"
                style="font-size: 14px !important"
                >Update</span
              >
            </button>
            <v-btn
              style="padding: 8px !important"
              class="custom-add-new-record-button btn_red__ch"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-form>
          <!-- update form end -->
        </div>
      </div>
    </div>
    <!-- Confirm Delete Item -->

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="380px">
        <v-card>
          <v-card-title>
            <span class="headline"
              >Do you really want to DELETE this item?</span
            >
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-submit-button" text @click="dialog = false"
              >No</v-btn
            >
            <v-btn class="custom-cancel-button" text @click="deleteItem"
              ><b-spinner small type="grow" v-if="deleting"></b-spinner
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import NewsAlertService from "@/MainServices/NewsAlertService.js";

export default {
  data() {
    return {
      loading: true,
      dialog: false,
      deleteId: null,
      deleting: false,
      success: false,
      token: `Bearer ${localStorage.getItem("token")}`,
      valid: true,
      createForm: true,
      updateForm: false,
      heading: "",
      link: "",
      usersTypesOptions: ["choose", "Job Seeker", "Direct Employer", "All"],
      users_type: "choose",
      nameRules: [(v) => !!v || "Heading is required"],
      headers: [
        { text: "Heading", value: "heading" },
        { text: "User Types", value: "users_type" },
        { text: "Action", value: "action" },
      ],
      data: [],
    };
  },
  mounted() {
    this.getInititalData();
  },
  methods: {
    getInititalData() {
      NewsAlertService.getNewsAlert()
        .then((res) => {
          this.data = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.createForm = true;
      this.updateForm = false;
      this.gender = "";
    },
    createFormEnable() {
      this.gender = "";
      this.createForm = true;
      this.updateForm = false;
    },

    createNewsAlert() {
      if (this.heading == "") {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      NewsAlertService.addNewNewsAlert({
        heading: this.heading,
        link: this.link,
        users_type: this.users_type,
      })
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.heading = "";
          this.link = "";
          this.users_type = "";
          Swal.fire({
            title: "",
            text: "News Alert added",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getInititalData();
          this.cancel();
        })
        .catch((error) => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    updateNewsAlert() {
      NewsAlertService.editNewsAlert(
        {
          heading: this.heading,
          link: this.link,
          users_type: this.users_type,
        },
        this.news_alert_id
      )
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.heading = "";
          this.link = "";
          this.news_alert_id = "";
          Swal.fire({
            title: "",
            text: "News Alert updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getInititalData();
          this.cancel();
        })
        .catch((error) => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    editButtonClick(item) {
      this.createForm = false;
      this.updateForm = true;
      this.heading = item.heading;
      this.link = item.link;
      this.users_type = item.users_type;
      this.news_alert_id = item.id;
    },

    deleteButtonClick(item) {
      this.deleting = true;
      NewsAlertService.deleteNewsAlert(item)
        .then((res) => {
          Swal.fire({
            title: "",
            text: "News Alert deleted",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.dialog = false;
          this.deleteId = null;
          this.deleting = false;
          this.getInititalData();
          this.loading = false;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.loading = false;
        });
    },

    askUserAboutDelete(item) {
      this.deleteId = item.id;
      this.dialog = true;
    },

    deleteItem() {
      this.deleteButtonClick(this.deleteId);
    },
  },
};
</script>
